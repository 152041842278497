import { render, staticRenderFns } from "./AlinaYandexMap.vue?vue&type=template&id=67d4b436&scoped=true&"
import script from "./AlinaYandexMap.vue?vue&type=script&lang=js&"
export * from "./AlinaYandexMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d4b436",
  null
  
)

export default component.exports