<template>
  <div class="row no-gutters mt-5 alina-form p-5">
    <div class="col">
      Здесь люди просто тусят.

      Коллектив индивидуалистов.

      Славные ублюдки.

      Идейная синь.

      ОчУмелые ручки.

      Лесная аристократия.

      И прочие достойные персонажи.
      <br>
      <br>
      Муза куража: <a href="https://www.facebook.com/sllimrut">Slimrut</a>
      <br>
      Ведущий проекта: <a href="https://vk.com/vsevolod.azovsky">Всеволод Азовский</a>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {}
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
